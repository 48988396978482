const { INIT_OBJECTIVE, REFERENCE_SOLUTION, UNIT } = require('../../constants');
const {
  getMaxHPGHeatBtesPowerMax,
  getMaxHPGColdBtesPowerMax,
  getMaxHPAPowerMax,
  getMaxHPGPowerMaxPerSst,
  getMaxHPAPowerMaxPerSst,
  getMaxGasPowerMax,
  getMaxGasPowerMaxPerSst
} = require('../../utils/calculationData.utils');
const { isNull } = require('../../utils/data.utils');

function getCommonConstraints(years) {
  if (years === '' || years < 0) years = null;
  return {
    InitSimulationCalcul: {
      key: 'InitSimulationCalcul',
      type: 'boolean',
      default: undefined
    },
    InitYearsSimulations: {
      key: 'InitYearsSimulations',
      min: 1,
      max: 30,
      type: 'number',
      required: true,
      default: 20,
      unit: UNIT.YEAR
    },
    InitBalanceDuration: {
      key: 'InitBalanceDuration',
      min: 1,
      max: years > 20 || isNull(years) ? 20 : years,
      type: 'number',
      required: true,
      default: 20,
      unit: UNIT.YEAR
    }
  };
}

const GENERAL = Object.freeze({
  InitObjective: {
    key: 'InitObjective',
    type: 'string',
    values: Object.values(INIT_OBJECTIVE),
    translateValuesIds: ['opti.capexAndOpex', 'opti.tri'], // todo dans le front
    required: true,
    default: INIT_OBJECTIVE.CAPEX_OPEX
  },
  InitReferenceSolution: {
    key: 'InitReferenceSolution',
    type: 'string',
    values: Object.values(REFERENCE_SOLUTION),
    translateValuesIds: ['opti.gas', 'opti.hpa'], // todo dans le front
    required: true,
    default: REFERENCE_SOLUTION.GAS_CHILLER
  }
});

function getBoundsConstraints(selectedProjects) {
  const hpgHeatMax = getMaxHPGHeatBtesPowerMax(selectedProjects);
  const hpgColdMax = getMaxHPGColdBtesPowerMax(selectedProjects);
  return {
    BtesLength: {
      key: 'BtesLength',
      min: 0,
      max: 100000,
      type: 'number',
      required: true,
      default: [0, 10000]
    },
    BtesProbeDepth: {
      key: 'BtesProbeDepth',
      values: [50, 75, 100, 150, 200, 250, 300, 400, 600, 800],
      required: true,
      default: [100, 200]
    },
    BtesProbeDistance: {
      key: 'BtesProbeDistance',
      values: [3, 6, 8, 10, 12, 15, 20, 24, 30],
      required: true,
      default: [8, 12]
    },
    T2final_Btes: {
      key: 'T2final_Btes',
      min: 3,
      max: 34,
      type: 'number',
      required: true,
      default: [8, 15]
    },
    HPGHeatBtesPowerMax: {
      key: 'HPGHeatBtesPowerMax',
      min: 0,
      max: hpgHeatMax,
      type: 'number',
      required: true,
      default: [0, Math.ceil(hpgHeatMax - 1)]
    },
    HPGColdBtesPowerMax: {
      key: 'HPGColdBtesPowerMax',
      min: 0,
      max: hpgColdMax,
      type: 'number',
      required: true,
      default: [0, Math.ceil(hpgColdMax - 1)]
    },
    SolarThermalSurface: {
      key: 'SolarThermalSurface',
      min: 0,
      max: 1000000,
      type: 'number',
      required: true,
      default: [0, 0]
    },
    KiloWattCretePV: {
      key: 'KiloWattCretePV',
      min: 0,
      max: 1000000,
      type: 'number',
      default: [0, 0]
    },
    QItesMax: {
      key: 'QItesMax',
      min: 0,
      max: 1000000,
      type: 'number',
      default: [0, 0]
    }
  };
}

// todo implémenter la multi sous-stations côté opti
function getSstBoundsConstraints(sst, selectedProjects) {
  const hpaMax = getMaxHPAPowerMax(selectedProjects);
  const gasMax = getMaxGasPowerMax(selectedProjects);
  return {
    HPAPowerMax: {
      key: 'HPAPowerMax',
      min: 0,
      max: hpaMax,
      type: 'number',
      required: true,
      default: [0, Math.ceil(hpaMax - 1)]
    },
    GasPowerMax: {
      key: 'GasPowerMax',
      min: 0,
      max: gasMax,
      type: 'number',
      default: [0, Math.ceil(gasMax - 1)]
    }
  };
}

const OPTI_GOAL = Object.freeze({
  InitConstraintEnabled: {
    key: 'InitConstraintEnabled',
    default: false
  },
  InitConstraintReference: {
    key: 'InitConstraintReference',
    min: 0,
    max: Infinity,
    type: 'number',
    default: 0,
    required: true
  },
  InitConstraintEnergyPrimary: {
    key: 'InitConstraintEnergyPrimary',
    ref: true,
    unit: UNIT.PRIMARY_ENERGY_KILOWATT_HOUR,
    min: -10,
    max: 100,
    type: 'number',
    default: 0,
    required: true
  },
  InitConstraintEnergyFinale: {
    key: 'InitConstraintEnergyFinale',
    ref: true,
    unit: UNIT.KILOWATT_HOUR_PER_YEAR,
    min: -10,
    max: 100,
    type: 'number',
    default: 0,
    required: true
  },
  InitConstraintCO2kg: {
    key: 'InitConstraintCO2kg',
    ref: true,
    unit: UNIT.KILOGRAM_CO2EQ_PER_YEAR,
    min: -10,
    max: 100,
    type: 'number',
    default: 0,
    required: true
  },
  InitConstraintENR: {
    key: 'InitConstraintENR',
    ref: false,
    unit: null,
    min: -10,
    max: 100,
    type: 'number',
    default: 0,
    required: true,
    preservesFurtherReferences: true
  },
  InitConstraintHpg: {
    key: 'InitConstraintHpg',
    ref: false,
    unit: null,
    min: 0,
    max: 100,
    type: 'number',
    default: 0,
    required: true,
    preservesFurtherReferences: true
  },
  InitConstraintGasPart: {
    key: 'InitConstraintGasPart',
    ref: false,
    unit: null,
    min: -10,
    max: 100,
    type: 'number',
    default: 100,
    required: true,
    preservesFurtherReferences: true
  },
  InitConstraintNbOptim: {
    key: 'InitConstraintNbOptim',
    min: 1,
    max: 5,
    default: 1,
    type: 'number'
  }
});

const OPTI_GOALS = [
  OPTI_GOAL.InitConstraintEnergyPrimary,
  OPTI_GOAL.InitConstraintEnergyFinale,
  OPTI_GOAL.InitConstraintCO2kg,
  OPTI_GOAL.InitConstraintENR,
  OPTI_GOAL.InitConstraintHpg,
  OPTI_GOAL.InitConstraintGasPart
];

const ITERATIONS = Object.freeze({
  FuncEvaluations: {
    key: 'FuncEvaluations',
    min: 0,
    max: Infinity,
    type: 'number',
    required: true,
    default: 2500
  },
  ConfigurableIterations: {
    key: 'configurableIterations',
    min: 1,
    max: Infinity,
    type: 'number',
    required: true,
    default: 3000
  }
});

function getParamsConstraints(selectedProject) {
  const hpgHeatMax = getMaxHPGHeatBtesPowerMax(selectedProject);
  const hpgColdMax = getMaxHPGColdBtesPowerMax(selectedProject);
  const constraints = {
    BtesLength: {
      key: 'BtesLength',
      max: 100000,
      type: 'number',
      required: true,
      default: 0,
      unit: UNIT.METER
    },
    BtesProbeDepth: {
      key: 'BtesProbeDepth',
      values: [50, 75, 100, 150, 200, 250, 300, 400, 600, 800],
      type: 'number',
      required: true,
      default: 100,
      unit: UNIT.METER
    },
    BtesProbeDistance: {
      key: 'BtesProbeDistance',
      values: [3, 6, 8, 10, 12, 15, 20, 24, 30],
      type: 'number',
      required: true,
      default: 10,
      unit: UNIT.METER
    },
    T2final_Btes: {
      key: 'T2final_Btes',
      min: 3,
      max: 34,
      type: 'number',
      required: true,
      default: 10,
      unit: UNIT.CELSIUS_DEGREE
    },
    HPGHeatBtesPowerMax: {
      key: 'HPGHeatBtesPowerMax',
      min: 0,
      max: hpgHeatMax,
      type: 'number',
      required: true,
      default: Math.ceil(hpgHeatMax - 1),
      unit: UNIT.KILOWATT
    },
    HPGColdBtesPowerMax: {
      key: 'HPGColdBtesPowerMax',
      min: 0,
      max: hpgColdMax,
      type: 'number',
      required: true,
      default: Math.ceil(hpgColdMax - 1),
      unit: UNIT.KILOWATT
    },
    SolarThermalSurface: {
      key: 'SolarThermalSurface',
      min: 0,
      max: 1000000,
      type: 'number',
      default: 0,
      unit: UNIT.SQUARE_METER
    },
    KiloWattCretePV: {
      key: 'KiloWattCretePV',
      min: 0,
      max: 1000000,
      type: 'number',
      default: 0,
      unit: UNIT.KILOWATT_PEAK
    },
    QItesMax: {
      key: 'QItesMax',
      min: 0,
      max: 1000000,
      type: 'number',
      default: 0,
      unit: UNIT.KILOWATT_HOUR
    }
  };
  if (selectedProject?.otherValues?.InitBtesEnabled) {
    constraints.BtesLength.exclusiveMin = 0;
  } else {
    constraints.BtesLength.min = 0;
  }
  return constraints;
}

function getSstParamsConstraints(sst, project) {
  const hpgMax = getMaxHPGPowerMaxPerSst(sst, project);
  const hpaMax = getMaxHPAPowerMaxPerSst(sst, project);
  const gasMax = getMaxGasPowerMaxPerSst(sst, project);
  return {
    HPGPowerMaxPerSst: {
      key: 'HPGPowerMaxPerSST',
      min: 0,
      max: hpgMax,
      type: 'number',
      default: Math.ceil(hpgMax - 1),
      unit: UNIT.KILOWATT
    },
    HPAPowerMaxPerSst: {
      key: 'HPAPowerMax',
      min: 0,
      max: hpaMax,
      type: 'number',
      required: true,
      default: Math.ceil(hpaMax - 1),
      unit: UNIT.KILOWATT
    },
    GasPowerMaxPerSst: {
      key: 'GasPowerMax',
      min: 0,
      max: gasMax,
      type: 'number',
      default: Math.ceil(gasMax - 1),
      unit: UNIT.KILOWATT
    }
  };
}

const SIMU_GOAL = Object.freeze({
  LbHpgPartTargetEnabled: {
    key: 'LbHpgPartTargetEnabled',
    type: 'boolean',
    default: false
  },
  InitConstraintHpg: {
    key: 'InitConstraintHpg',
    unit: null,
    min: 0,
    max: 100,
    type: 'number',
    default: 0,
    preservesFurtherReferences: true
  }
});

const SIMU_GOALS = [SIMU_GOAL.InitConstraintHpg];

module.exports = {
  GENERAL,
  OPTI_GOAL,
  OPTI_GOALS,
  ITERATIONS,
  SIMU_GOAL,
  SIMU_GOALS,
  getCommonConstraints,
  getBoundsConstraints,
  getSstBoundsConstraints,
  getParamsConstraints,
  getSstParamsConstraints
};
