import {
  PV_EQUIPMENT,
  ST_EQUIPMENT
} from '../../../../../../../../server/constants';
import {
  EXCHANGER,
  GEOCOOLING,
  HEADLOSS,
  HP,
  ITES,
  getPVParams,
  getSTParams
} from '../../../../../../../../server/models/design/performance.model';
import { roundNumber } from '../../../../../../utils/data.utils';

const getModel = (models, value) => {
  return models.find((model) => model.value === value);
};

export const perfTemplates = (i18n, project) => {
  const { InitSolarThermalModel, InitPhotovoltaicModel } = project.otherValues;
  const pvModel = getModel(Object.values(PV_EQUIPMENT), InitPhotovoltaicModel);
  const stModel = getModel(Object.values(ST_EQUIPMENT), InitSolarThermalModel);
  const PV = getPVParams(pvModel);
  const ST = getSTParams(stModel);
  return [
    {
      title: i18n._('performance.heatPumps'),
      rows: [
        {
          label: i18n._('performance.HPGFilename'),
          value: project.otherValues.HPGFilename
        },
        {
          label: i18n._('performance.HPAFilename'),
          value: project.otherValues.HPAFilename
        },
        {
          label: i18n._('performance.InitInjectionTemperatureDelta'),
          value: project.otherValues.InitInjectionTemperatureDelta,
          unit: HP.InitInjectionTemperatureDelta.unit
        }
      ]
    },
    {
      title: i18n._('performance.gasBoiler'),
      rows: [
        {
          label: i18n._('performance.InitGasEfficiency'),
          value: project.otherValues.InitGasEfficiency
        }
      ]
    },
    {
      title: i18n._('performance.exchangers'),
      rows: [
        {
          label: i18n._('performance.InitLoopExchangerPinch'),
          value: project.otherValues.InitLoopExchangerPinch,
          unit: EXCHANGER.InitLoopExchangerPinch.unit
        },
        {
          label: i18n._('performance.InitLoopExchangerU'),
          value: project.otherValues.InitLoopExchangerU,
          unit: EXCHANGER.InitLoopExchangerU.unit
        },
        {
          label: i18n._('performance.InitLoopExchangerAirPinch'),
          value: project.otherValues.InitLoopExchangerAirPinch,
          unit: EXCHANGER.InitLoopExchangerAirPinch.unit
        },
        {
          label: i18n._('performance.InitLoopExchangerAirU'),
          value: project.otherValues.InitLoopExchangerAirU,
          unit: EXCHANGER.InitLoopExchangerAirU.unit
        },
        {
          label: i18n._('performance.InitStationExchangerPinch'),
          value: project.otherValues.InitStationExchangerPinch,
          unit: EXCHANGER.InitStationExchangerPinch.unit
        },
        {
          label: i18n._('performance.InitStationColdExchangerPinch'),
          value: project.otherValues.InitStationColdExchangerPinch,
          unit: EXCHANGER.InitStationColdExchangerPinch.unit
        },
        {
          label: i18n._('performance.InitStationHotwaterExchangerPinch'),
          value: project.otherValues.InitStationHotwaterExchangerPinch,
          unit: EXCHANGER.InitStationHotwaterExchangerPinch.unit
        }
      ]
    },
    {
      title: i18n._('performance.headloss'),
      rows: [
        {
          label: i18n._('performance.InitPumpEfficiency'),
          value: project.otherValues.InitPumpEfficiency
        },
        {
          label: i18n._('performance.InitExchangerHeadlossMceMax'),
          value: project.otherValues.InitExchangerHeadlossMceMax,
          unit: HEADLOSS.InitExchangerHeadlossMceMax.unit
        },
        {
          label: i18n._('performance.InitBtesHeadlossLimit'),
          value: project.otherValues.InitBtesHeadlossLimit,
          unit: HEADLOSS.InitBtesHeadlossLimit.unit
        },
        {
          label: i18n._('performance.InitLoopConnectionLength'),
          value: project.otherValues.InitLoopConnectionLength,
          unit: HEADLOSS.InitLoopConnectionLength.unit
        }
      ]
    },
    {
      title: i18n._('performance.geocooling'),
      rows: [
        {
          label: i18n._('description.InitGeocoolingEnabled'),
          value: project.otherValues.InitGeocoolingEnabled
            ? i18n._('yes')
            : i18n._('no')
        },
        {
          label: i18n._('performance.InitGeocoolingPumpConsumptionPercent'),
          value: project.otherValues.InitGeocoolingPumpConsumptionPercent,
          unit: GEOCOOLING.InitGeocoolingPumpConsumptionPercent.unit
        }
      ]
    },
    {
      title: i18n._('performance.solarThermal'),
      rows: [
        {
          label: i18n._('performance.InitSolarSurfaceTilt'),
          value: project.otherValues.InitSolarSurfaceTilt,
          unit: ST.InitSolarSurfaceTilt.unit
        },
        {
          label: i18n._('performance.InitSolarSurfaceAzimuth'),
          value: project.otherValues.InitSolarSurfaceAzimuth,
          unit: ST.InitSolarSurfaceAzimuth.unit
        },
        {
          label: i18n._('performance.InitSolarThermalHeta0'),
          value: project.otherValues.InitSolarThermalHeta0
        },
        {
          label: i18n._('performance.InitSolarThermalBu'),
          value: project.otherValues.InitSolarThermalBu,
          unit: ST.InitSolarThermalBu.unit
        },
        {
          label: i18n._('performance.InitSolarThermalB1'),
          value: project.otherValues.InitSolarThermalB1,
          unit: ST.InitSolarThermalB1.unit
        },
        {
          label: i18n._('performance.InitSolarThermalB2'),
          value: project.otherValues.InitSolarThermalB2,
          unit: ST.InitSolarThermalB2.unit
        },
        {
          label: i18n._('performance.InitSolarThermalEpsa'),
          value: project.otherValues.InitSolarThermalEpsa
        },
        {
          label: i18n._('performance.InitSolarThermalFlowRate'),
          value: project.otherValues.InitSolarThermalFlowRate,
          unit: ST.InitSolarThermalFlowRate.unit
        },
        {
          label: i18n._('performance.InitSolarDeltaTemp'),
          value: project.otherValues.InitSolarDeltaTemp,
          unit: ST.InitSolarDeltaTemp.unit
        },
        {
          label: i18n._('performance.InitSolarTemperatureMin'),
          value: project.otherValues.InitSolarTemperatureMin,
          unit: ST.InitSolarTemperatureMin.unit
        }
      ]
    },
    {
      title: i18n._('performance.photovoltaic'),
      rows: [
        {
          label: i18n._('performance.InitPhotovoltaicModel'),
          value: project.otherValues.InitPhotovoltaicModel
        },
        {
          label: i18n._('performance.InitPVEfficiency'),
          value: roundNumber(project.otherValues.InitPVEfficiency * 100, 4),
          unit: PV.InitPVEfficiency.unit
        },
        {
          label: i18n._('performance.InitPVDCLossesAgeModule'),
          value: roundNumber(
            project.otherValues.InitPVDCLossesAgeModule * 100,
            4
          ),
          unit: PV.InitPVDCLossesAgeModule.unit
        },
        {
          label: i18n._('performance.InitPVTemperatureCoefGammaModule'),
          value: roundNumber(
            project.otherValues.InitPVTemperatureCoefGammaModule * 100,
            4
          ),
          unit: PV.InitPVTemperatureCoefGammaModule.unit
        },
        {
          label: i18n._('performance.InitPVInverterMaxPowerPerWc'),
          value: project.otherValues.InitPVInverterMaxPowerPerWc,
          unit: PV.InitPVInverterMaxPowerPerWc.unit
        },
        {
          label: i18n._('performance.InitPVInverterNominalEfficiency'),
          value: roundNumber(
            project.otherValues.InitPVInverterNominalEfficiency * 100,
            4
          )
        },
        {
          label: i18n._('performance.InitSolarSurfaceTilt'),
          value: project.otherValues.InitSolarSurfaceTilt,
          unit: ST.InitSolarSurfaceTilt.unit
        },
        {
          label: i18n._('performance.InitSolarSurfaceAzimuth'),
          value: project.otherValues.InitSolarSurfaceAzimuth,
          unit: ST.InitSolarSurfaceAzimuth.unit
        }
      ]
    },
    {
      title: i18n._('performance.coldStorage'),
      rows: [
        {
          label: i18n._('performance.ites'),
          value: project.otherValues.InitItesModel
        },
        {
          label: i18n._('performance.InitItesPumpConsumptionPercentage'),
          value: project.otherValues.InitItesPumpConsumptionPercentage,
          unit: ITES.InitItesPumpConsumptionPercentage.unit
        },
        {
          label: i18n._('performance.InitItesChargingTemperatureIn'),
          value: project.otherValues.InitItesChargingTemperatureIn,
          unit: ITES.InitItesChargingTemperatureIn.unit
        },
        {
          label: i18n._('performance.InitItesDischargingTemperatureIn'),
          value: project.otherValues.InitItesDischargingTemperatureIn,
          unit: ITES.InitItesDischargingTemperatureIn.unit
        },
        {
          label: i18n._('performance.InitItesChargingTemperatureOut'),
          value: project.otherValues.InitItesChargingTemperatureOut,
          unit: ITES.InitItesChargingTemperatureOut.unit
        },
        {
          label: i18n._('performance.InitItesDischargingTemperatureOut'),
          value: project.otherValues.InitItesDischargingTemperatureOut,
          unit: ITES.InitItesDischargingTemperatureOut.unit
        }
      ]
    }
  ];
};
