import { useLingui } from '@lingui/react';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { GEOCOOLING } from '../../../../../../server/models/design/performance.model';
import Bloc from '../../../../components/Bloc/Bloc';
import ParamInput from '../../../../components/ParamForm/ParamInput';
import { INPUT_TYPE } from '../../../../constants';
import { getDefaultValue } from '../../../../utils/param.utils';

const GeocoolingBloc = ({
  form,
  onInputChange
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Bloc title={i18n._('performance.geocooling')}>
      <Row>
        <Col>
          <ParamInput
            label={i18n._('performance.InitGeocoolingPumpConsumptionPercent')}
            value={form.InitGeocoolingPumpConsumptionPercent}
            param={GEOCOOLING.InitGeocoolingPumpConsumptionPercent}
            onChange={(value) =>
              onInputChange('InitGeocoolingPumpConsumptionPercent', value)
            }
            bottomText={getDefaultValue(
              i18n,
              GEOCOOLING.InitGeocoolingPumpConsumptionPercent
            )}
            unit
            type={INPUT_TYPE.NUMBER}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default React.memo(GeocoolingBloc);
