import { UNIT } from '../../../../../../../server/constants';
import {
  canDisplayReference,
  getSummaryRefPath
} from '../../../../../utils/compute.utils';
import { getSafeData } from '../../../../../utils/data.utils';

export const capexBySstTemplates = (i18n, results, sst) => {
  const { inp } = results[0].ComputeResult;

  let gasBoilerLabel = '';
  if (!inp.InitGasEnabled) {
    gasBoilerLabel = i18n._(
      'results.economicAnalysis.productionUnits.gasBoiler'
    );
  } else {
    gasBoilerLabel = inp.InitReleveEnabled
      ? i18n._('results.economicAnalysis.productionUnits.backupGasBoiler')
      : i18n._('results.economicAnalysis.productionUnits.auxiliaryGasBoiler');
  }

  return [
    {
      title: i18n._('results.economicAnalysis.productionUnits'),
      rows: [
        {
          label: i18n._('results.economicAnalysis.productionUnits.hpg'),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.CAPEX.NOBTES.Marginless.PerSub.HPG[
                sst
                ]
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._('results.economicAnalysis.productionUnits.hpa'),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.CAPEX.NOBTES.Marginless.PerSub.HPA[
                sst
                ]
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: gasBoilerLabel,
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.CAPEX.NOBTES.Marginless.PerSub.Gas[
                sst
                ]
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._(
            'results.economicAnalysis.distributionModules.moduleHeat'
          ),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.CAPEX.NOBTES.Marginless.PerSub
                  .ModuleHeat[sst]
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._(
            'results.economicAnalysis.distributionModules.moduleCold'
          ),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.CAPEX.NOBTES.Marginless.PerSub
                  .ModuleCold[sst]
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._(
            'results.economicAnalysis.distributionModules.moduleEcs'
          ),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.CAPEX.NOBTES.Marginless.PerSub
                  .ModuleEcs[sst]
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._(
            'results.economicAnalysis.distributionModules.moduleInjection'
          ),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.CAPEX.NOBTES.Marginless.PerSub
                  .ModuleInjection[sst]
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._(
            'results.economicAnalysis.distributionModules.hydraulicLot'
          ),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.CAPEX.NOBTES.Marginless.PerSub
                  .LotHydraulique[sst]
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._('results.economicAnalysis.distributionModules.elecLot'),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.CAPEX.NOBTES.Marginless.PerSub
                  .LotElectrique[sst]
            )
          ),
          unit: UNIT.EURO
        }
      ]
    }
  ];
};

export const capexTotalTemplates = (i18n, results) => {
  const { inp } = results[0].ComputeResult;

  let gasBoilerLabel = '';
  if (!inp.InitGasEnabled) {
    gasBoilerLabel = i18n._(
      'results.economicAnalysis.productionUnits.gasBoiler'
    );
  } else {
    gasBoilerLabel = inp.InitReleveEnabled
      ? i18n._('results.economicAnalysis.productionUnits.backupGasBoiler')
      : i18n._('results.economicAnalysis.productionUnits.auxiliaryGasBoiler');
  }
  return [
    {
      title: i18n._('results.economicAnalysis.loopAndBtes'),
      rows: [
        {
          label: i18n._('results.economicAnalysis.loopAndBtes'),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.CAPEX.BTES.Marginless.BTES
            )
          ),
          unit: UNIT.EURO
        }
      ]
    },
    {
      title: i18n._('results.economicAnalysis.productionUnits'),
      rows: [
        {
          label: i18n._('results.economicAnalysis.productionUnits.hpg'),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.CAPEX.NOBTES.Marginless.HPG
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._('results.economicAnalysis.productionUnits.hpa'),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.CAPEX.NOBTES.Marginless.HPA
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: gasBoilerLabel,
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.CAPEX.NOBTES.Marginless.Gas
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._('results.economicAnalysis.productionUnits.st'),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.CAPEX.NOBTES.Marginless.ST
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._('results.economicAnalysis.productionUnits.stModule'),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.CAPEX.NOBTES.Marginless.STModule
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._('results.economicAnalysis.productionUnits.pv'),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.CAPEX.NOBTES.Marginless.PVPanels
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._('results.economicAnalysis.productionUnits.pvInverter'),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.CAPEX.NOBTES.Marginless.PVInverter
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._('results.economicAnalysis.productionUnits.ites'),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.CAPEX.NOBTES.Marginless.Ites
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._(
            'results.economicAnalysis.distributionModules.moduleHeat'
          ),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.CAPEX.NOBTES.Marginless.ModuleHeat
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._(
            'results.economicAnalysis.distributionModules.moduleCold'
          ),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.CAPEX.NOBTES.Marginless.ModuleCold
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._(
            'results.economicAnalysis.distributionModules.moduleEcs'
          ),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.CAPEX.NOBTES.Marginless.ModuleEcs
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._(
            'results.economicAnalysis.distributionModules.moduleInjection'
          ),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.CAPEX.NOBTES.Marginless
                  .ModuleInjectionTotal
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._(
            'results.economicAnalysis.distributionModules.surplusSurinjectionHeadloss'
          ),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.CAPEX.NOBTES.Marginless
                  .SurplusSurinjectionHeadloss
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._(
            'results.economicAnalysis.distributionModules.hydraulicLot'
          ),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.CAPEX.NOBTES.Marginless
                  .LotHydrauliqueTotal
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._('results.economicAnalysis.distributionModules.elecLot'),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.CAPEX.NOBTES.Marginless
                  .LotElectriqueTotal
            )
          ),
          unit: UNIT.EURO
        }
      ]
    },
    {
      title: i18n._('results.economicAnalysis.subsidies'),
      rows: [
        {
          label: i18n._('results.economicAnalysis.subsidies.total.amount'),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.Subventions.Total.Amount
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._('results.economicAnalysis.subsidies.heat.base'),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.Subventions.Heat.Base
            )
          ),
          unit: UNIT.MEGAWATT_HOUR
        },
        {
          label: i18n._('results.economicAnalysis.subsidies.heat.amount'),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.Subventions.Heat.Amount
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._(
            'results.economicAnalysis.subsidies.heat.unitSubvention'
          ),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.Subventions.Heat.UnitSubvention
            )
          ),
          unit: UNIT.EURO_PER_MEGAWATT_HOUR
        },
        {
          label: i18n._('results.economicAnalysis.subsidies.cold.base'),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.Subventions.Cold.Base
            )
          ),
          unit: UNIT.MEGAWATT_HOUR
        },
        {
          label: i18n._('results.economicAnalysis.subsidies.cold.amount'),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.Subventions.Cold.Amount
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._(
            'results.economicAnalysis.subsidies.cold.unitSubvention'
          ),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.Subventions.Cold.UnitSubvention
            )
          ),
          unit: UNIT.EURO_PER_MEGAWATT_HOUR
        },
        {
          label: i18n._('results.economicAnalysis.subsidies.geocooling.base'),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.Subventions.Geocooling.Base
            )
          ),
          unit: UNIT.MEGAWATT_HOUR
        },
        {
          label: i18n._('results.economicAnalysis.subsidies.geocooling.amount'),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.Subventions.Geocooling.Amount
            )
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._(
            'results.economicAnalysis.subsidies.geocooling.unitSubvention'
          ),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary.Subventions.Geocooling
                  .UnitSubvention
            )
          ),
          unit: UNIT.EURO_PER_MEGAWATT_HOUR
        }
      ]
    }
  ];
};

export const capexTotalCompareTemplate = (i18n, results) => {
  return {
    rows: [
      {
        label: i18n._(
          'results.economicAnalysis.total.totalCapexWithoutFondsChaleur'
        ),
        refValues: results.map((result) => {
          const { inp } = result.ComputeResult;
          const summaryPath = getSummaryRefPath(inp);
          return canDisplayReference(inp)
            ? getSafeData(() => result.ComputeResult[summaryPath].CAPEX.Total)
            : null;
        }),
        accentaValues: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.CAPEX.Total)
        ),
        unit: UNIT.EURO
      },
      {
        label: i18n._(
          'results.economicAnalysis.total.totalCapexWithFondsChaleur'
        ),
        refValues: results.map((result) => {
          const { inp } = result.ComputeResult;
          const summaryPath = getSummaryRefPath(inp);
          return canDisplayReference(inp)
            ? getSafeData(
              () =>
                result.ComputeResult[summaryPath].CAPEX.TotalAvecFondChaleur
            )
            : null;
        }),
        accentaValues: results.map((result) =>
          getSafeData(
            () => result.ComputeResult.summary.CAPEX.TotalAvecFondChaleur
          )
        ),
        unit: UNIT.EURO
      }
    ]
  };
};

export const opexTemplates = (i18n, results) => {
  return [
    {
      title: i18n._('results.economicAnalysis.energyCost'),
      rows: [
        {
          label: i18n._('results.economicAnalysis.energyCost.electricityCost'),
          values: results.map(
            (result) => result.ComputeResult.summary.AvgElecPrice
          ),
          unit: UNIT.EURO_PER_KILOWATT_HOUR,
          precision: 2
        },
        {
          label: i18n._('results.economicAnalysis.energyCost.gasCost'),
          values: results.map(
            (result) => result.ComputeResult.inp.InitGasPrice
          ),
          unit: UNIT.EURO_PER_KILOWATT_HOUR,
          precision: 2
        }
      ]
    },
    {
      title: i18n._('results.economicAnalysis.solarPhotovoltaic'),
      rows: [
        {
          label: i18n._('results.economicAnalysis.solarPhotovoltaic.opexSaved'),
          values: results.map(
            (result) => result.ComputeResult.summary.OpexPVSavedAverage
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._(
            'results.economicAnalysis.solarPhotovoltaic.opexResaleProject'
          ),
          values: results.map(
            (result) => result.ComputeResult.summary.OpexPVSoldToProjectAverage
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._(
            'results.economicAnalysis.solarPhotovoltaic.opexResaleNetwork'
          ),
          values: results.map(
            (result) => result.ComputeResult.summary.OpexPVSoldToGridAverage
          ),
          unit: UNIT.EURO
        }
      ]
    }
  ];
};

export const opexTotalCompareTemplate = (i18n, results) => {
  const { InitBalanceDuration } = results[0].ComputeResult.inp;
  return {
    rows: [
      {
        label: i18n._('results.economicAnalysis.total.totalOpexFullSimu', {
          years: InitBalanceDuration
        }),
        refValues: results.map((result) => {
          const { inp } = result.ComputeResult;
          const summaryPath = getSummaryRefPath(inp);
          return canDisplayReference(inp)
            ? getSafeData(() => result.ComputeResult[summaryPath].OPEX)
            : null;
        }),
        accentaValues: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.OPEX)
        ),
        unit: UNIT.EURO
      },
      {
        label: i18n._('results.economicAnalysis.total.opexAverage'),
        refValues: results.map((result) => {
          const { inp } = result.ComputeResult;
          const summaryPath = getSummaryRefPath(inp);
          return canDisplayReference(inp)
            ? getSafeData(
              () =>
                result.ComputeResult[summaryPath].OPEX / InitBalanceDuration
            )
            : null;
        }),
        accentaValues: results.map((result) =>
          getSafeData(
            () => result.ComputeResult.summary.OPEX / InitBalanceDuration
          )
        ),
        unit: UNIT.EURO
      }
    ]
  };
};

export const capexOpexTotalCompareTemplate = (i18n, results) => {
  return {
    rows: [
      {
        label: i18n._('results.economicAnalysis.total.totalCapexOpex', {
          years: results[0].ComputeResult.inp.InitBalanceDuration
        }),
        refValues: results.map((result) => {
          const { inp } = result.ComputeResult;
          const summaryPath = getSummaryRefPath(inp);
          return canDisplayReference(inp)
            ? getSafeData(() => result.ComputeResult[summaryPath].TotalCost)
            : null;
        }),
        accentaValues: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.TotalCost)
        ),
        unit: UNIT.EURO
      }
    ]
  };
};

export const totalOpexCapexTemplate = (i18n, results) => {
  return {
    rows: [
      {
        label: i18n._('results.economicAnalysis.total.tri'),
        values: results.map((result) => {
          const { summary, inp } = result.ComputeResult;
          return canDisplayReference(inp)
            ? !summary.Payback || summary.Payback !== -1
              ? summary.Payback
              : i18n._('results.economicAnalysis.total.payback', {
                duration: inp.InitBalanceDuration
              })
            : null;
        }),
        unit: UNIT.YEAR
      }
    ]
  };
};
