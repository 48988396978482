import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { PV_EQUIPMENT, ST_EQUIPMENT } from '../../../../../server/constants';
import { GEOCOOLING } from '../../../../../server/models/design/performance.model';
import { PERFORMANCE_SCHEMA } from '../../../../../server/validation/performance.validation';
import { fetchPerformance, updatePerformance } from '../../../api/project.api';
import AjvContext from '../../../contexts/AjvContext';
import PopupContext from '../../../contexts/PopupContext';
import { IS_DEV } from '../../../utils/env.utils';
import './PerformancePage.css';
import ExchangerBloc from './blocs/ExchangerBloc';
import GasBoilerBloc from './blocs/GasBoilerBloc';
import GeocoolingBloc from './blocs/GeocoolingBloc';
import HeadlossBloc from './blocs/HeadlossBloc';
import HeatPumpsBloc from './blocs/HeatPumpsBloc';
import ItesBloc from './blocs/ItesBloc';
import PhotovoltaicBloc from './blocs/PhotovoltaicBloc';
import SolarThermalBloc from './blocs/SolarThermalBloc';

const PerformancePage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const navigate = useNavigate();
  const { companyId, projectId } = useParams();
  //#endregion

  //#region [contexts]
  const { ajv } = useContext(AjvContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [form, setForm] = useState();
  //#endregion

  //#region [effects]
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const form = await fetchPerformance(projectId);
        setForm(() => form);
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, []);
  //#endregion

  //#region [methods]
  const handleInputChange = (key, value) => {
    setForm((oldForm) => ({ ...oldForm, [key]: value }));
  };

  const handleSolarThermalModelChange = (modelValue) => {
    const stModel = Object.values(ST_EQUIPMENT).find(
      (model) => model.value === modelValue
    );
    if (!stModel.data) return;
    setForm((oldForm) => {
      if (oldForm.InitPhotovoltaicModel !== stModel.linkedPv) {
        oldForm.InitPhotovoltaicModel = stModel.linkedPv;
      }
      Object.entries(stModel.data).forEach(([key, value]) => {
        oldForm[key] = value;
      });
      oldForm.InitSolarThermalModel = modelValue;
      return { ...oldForm };
    });
  };

  const handlePVModelChange = (modelValue) => {
    const pvModel = Object.values(PV_EQUIPMENT).find(
      (model) => model.value === form.InitPhotovoltaicModel
    );
    if (!pvModel.data) return;
    setForm((oldForm) => {
      if (pvModel.linkedSt !== form.InitSolarThermalModel) {
        oldForm.InitSolarThermalModel = pvModel.linkedSt;
      }
      Object.entries(pvModel.data).forEach(([key, value]) => {
        oldForm[key] = value;
      });
      oldForm.InitPhotovoltaicModel = modelValue;
      return { ...oldForm };
    });
  };

  const handleSubmitClick = async () => {
    try {
      await updatePerformance(projectId, form);
      navigate(
        `/company/${companyId}/project/${projectId}/design/compute?type=opti`
      );
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  if (!form) return null;
  const validate = ajv.compile(PERFORMANCE_SCHEMA);
  const isFormValid = validate(form);
  if (IS_DEV && validate.errors) console.log(validate.errors);
  return (
    <div className='performance-page'>
      <ExchangerBloc form={form} onInputChange={handleInputChange} />
      <SolarThermalBloc
        form={form}
        onInputChange={handleInputChange}
        onSolarThermalModelChange={handleSolarThermalModelChange}
      />
      <PhotovoltaicBloc
        form={form}
        onInputChange={handleInputChange}
        onPVModelChange={handlePVModelChange}
      />
      <GasBoilerBloc form={form} onInputChange={handleInputChange} />
      <HeatPumpsBloc
        form={form}
        setForm={setForm}
        onInputChange={handleInputChange}
      />
      <ItesBloc form={form} onInputChange={handleInputChange} />
      <HeadlossBloc form={form} onInputChange={handleInputChange} />
      <GeocoolingBloc
        form={form}
        onInputChange={handleInputChange}
      />
      <div className='bottom-page-btns'>
        <Button
          variant='primary'
          onClick={handleSubmitClick}
          disabled={!isFormValid}
        >
          {i18n._('save')}
        </Button>
      </div>
    </div>
  );
  //#endregion
};

export default PerformancePage;
