import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Alert, Form } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import { getColdProductionParams } from '../../../../../../../server/models/design/description.model';
import ParamInput from '../../../../../components/ParamForm/ParamInput';
import Section from '../../../../../components/Section/Section';
import TileCheckbox from '../../../../../components/Tile/TileCheckbox';
import { INPUT_TYPE } from '../../../../../constants';
import hpaImg from '../../../../../image/equipment-hpa.png';
import hpgImg from '../../../../../image/equipment-hpg.png';
import geoImg from '../../../../../image/equipment-geo.png';
import './ColdProductionSection.css';

const ColdProductionSection = ({
  form,
  formIndex,
  onInputChange,
  onTemperatureColdBackChange
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  const coldProductionParams = getColdProductionParams(
    form.InitTemperatureCold
  );
  const noEquipment =
    !form.InitHPGCoolingEnabled && !form.InitHPACoolingEnabled && !form.InitGeocoolingEnabled;
  return (
    <Section
      title={i18n._('description.coldProduction')}
      open
      className='description-production-section'
    >
      <div className='description-production-regimes'>
        <h2>{i18n._('description.coldProduction.regimes')}</h2>
        <div className='description-production-regimes-need-row'>
          <span className='param-input-label'>
            {i18n._('description.coldProduction.regimes.cold')}
          </span>
          <div>
            <ParamInput
              value={form.InitTemperatureCold}
              onChange={(value) => onInputChange('InitTemperatureCold', value)}
              param={coldProductionParams.InitTemperatureCold}
              unit
              type={INPUT_TYPE.NUMBER}
            />
            <ParamInput
              value={form.InitTemperatureColdBack}
              onChange={onTemperatureColdBackChange}
              param={coldProductionParams.InitTemperatureColdBack}
              unit
              type={INPUT_TYPE.NUMBER}
            />
          </div>
          <span className='param-input-small-text'>
            {i18n._('description.heatProduction.regimes.cold.default', {
              InitTemperatureCold:
                coldProductionParams.InitTemperatureCold.default,
              InitTemperatureColdBack:
                coldProductionParams.InitTemperatureColdBack.default
            })}
          </span>
        </div>
      </div>
      <div>
        <h2>{i18n._('description.coldProduction.equipment')}</h2>
        <div className='description-production-equipment-row'>
          {form.InitBtesEnabled && (
            <TileCheckbox
              checked={form.InitHPGCoolingEnabled}
              image={hpgImg}
              onChange={(checked) =>
                onInputChange('InitHPGCoolingEnabled', checked)
              }
              title={i18n._('description.InitHPGCoolingEnabled')}
            />
          )}
          <TileCheckbox
            checked={form.InitHPACoolingEnabled}
            image={hpaImg}
            onChange={(checked) => onInputChange(['InitHPACoolingEnabled', 'InitColdRangeEnabled'], [checked, false])}
            title={i18n._('description.InitHPACoolingEnabled')}
            disabled={form.ItesEnabled}
          />
          <TileCheckbox
            checked={form.InitGeocoolingEnabled || false}
            image={geoImg}
            onChange={(checked) =>
              onInputChange('InitGeocoolingEnabled', checked)
            }
            title={i18n._('description.InitGeocoolingEnabled')}
            disabled={form.ItesEnabled}
          />
        </div>
        {noEquipment && (
          <Alert variant='danger' className='description-equipment-alert'>
            {i18n._('description.error.noEquipment')}
          </Alert>
        )}
        {form.InitBtesEnabled &&
          !form.InitHPGHeatingEnabled &&
          !form.InitHPGCoolingEnabled && (
            <Alert variant='danger' className='description-equipment-alert'>
              {i18n._('description.error.noHpgEquipment')}
            </Alert>
          )}
      </div>

      {form.InitHPACoolingEnabled &&
        <div>
          <div className='heat-production-regimes-heat-wc-check'>
            <Form.Check
              type='switch'
              label={i18n._('description.InitColdRangeEnabled')}
              checked={form.InitColdRangeEnabled}
              onChange={(evt) =>
                onInputChange('InitColdRangeEnabled', evt.target.checked)
              }
              name='InitColdRangeEnabled'
              id={'InitColdRangeEnabled_' + formIndex}
            />
            <FontAwesomeIcon
              className='info-icon'
              icon='circle-question'
              data-tooltip-id='tile_radio_tooltip_InitColdRangeEnabled'
              data-tooltip-content={i18n._('description.InitColdRangeEnabledTooltip')}
            />
            <Tooltip
              id='tile_radio_tooltip_InitColdRangeEnabled'
              place='bottom'
              className='info-tooltip'
              opacity={1}
            />
          </div>
        </div>}
    </Section>
  );
  //#endregion
};

export default React.memo(ColdProductionSection);
