import React from 'react';
import './DescriptionTab.css';
import BuildingNeedsBloc from './blocs/BuildingNeedsBloc/BuildingNeedsBloc';
import DescriptionNameBloc from './blocs/DescriptionNameBloc';
import EnergyStorageBloc from './blocs/EnergyStorageBloc/EnergyStorageBloc';
import GeostorageBloc from './blocs/GeostorageBloc';
import SubstationBloc from './blocs/SubstationBloc/SubstationBloc';
import WeatherBloc from './blocs/WeatherBloc/WeatherBloc';
import ColdProductionSection from './sections/ColdProductionSection/ColdProductionSection';
import ElectricProductionSection from './sections/ElectricProductionSection';
import HeatProductionSection from './sections/HeatProductionSection/HeatProductionSection';

const DescriptionTab = ({
  form,
  formIndex,
  weatherForm,
  stdForms,
  onHeatChange,
  onHotwaterChange,
  onColdChange,
  onRefreshingChange,
  onNoStorageChange,
  onBtesChange,
  onItesChange,
  onInputChange,
  onTemperatureHeatBackChange,
  onGasReleveChange,
  onGasAppointChange,
  onTemperatureColdBackChange,
  onSstInputChange,
  onNewSstClick,
  onStdFileChange,
  onSstDelete,
  onWeatherFileChange
}) => {
  //#region [render]
  return (
    <div className='description-tab'>
      <DescriptionNameBloc form={form} onInputChange={onInputChange} />
      <BuildingNeedsBloc
        form={form}
        formIndex={formIndex}
        onHeatChange={onHeatChange}
        onHotwaterChange={onHotwaterChange}
        onColdChange={onColdChange}
        onRefreshingChange={onRefreshingChange}
      />
      <EnergyStorageBloc
        form={form}
        onNoStorageChange={onNoStorageChange}
        onBtesChange={onBtesChange}
        onItesChange={onItesChange}
      />
      {form.InitBtesEnabled && (
        <GeostorageBloc
          form={form}
          formIndex={formIndex}
          onInputChange={onInputChange}
        />
      )}
      {(form.InitStationHasHeatNeeds || form.InitStationHasHotWaterNeeds) && (
        <HeatProductionSection
          form={form}
          formIndex={formIndex}
          onInputChange={onInputChange}
          onTemperatureHeatBackChange={onTemperatureHeatBackChange}
          onGasReleveChange={onGasReleveChange}
          onGasAppointChange={onGasAppointChange}
        />
      )}
      {(form.InitStationHasColdNeeds || form.InitRefreshingEnabled) && (
        <ColdProductionSection
          form={form}
          onInputChange={onInputChange}
          onTemperatureColdBackChange={onTemperatureColdBackChange}
        />
      )}
      <ElectricProductionSection form={form} onInputChange={onInputChange} />
      <SubstationBloc
        form={form}
        formIndex={formIndex}
        stdForms={stdForms}
        onInputChange={onSstInputChange}
        onNewSstClick={onNewSstClick}
        onFileChange={onStdFileChange}
        onSstDelete={onSstDelete}
      />
      <WeatherBloc
        weatherForm={weatherForm}
        onFileChange={onWeatherFileChange}
      />
    </div>
  );
  //#endregion
};

export default React.memo(DescriptionTab);
