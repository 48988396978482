const {
  AhsID,
  NAME,
  NEEDS,
  ENERGY_STORAGE,
  GEOSTORAGE,
  HOTWATER_PRODUCTION,
  SST,
  ELECTRIC_PRODUCTION,
  WEATHER,
  getHeatProductionParams,
  getColdProductionParams
} = require('../models/design/description.model');

const getDescriptionSchema = (
  heatTemperature,
  coldTemperature,
  waterLawIsUsed
) => {
  const HEAT_PRODUCTION = getHeatProductionParams(heatTemperature);
  const COLD_PRODUCTION = getColdProductionParams(coldTemperature);
  return {
    type: 'object',
    properties: {
      [AhsID.AhsID.key]: {
        type: AhsID.AhsID.type,
        pattern: AhsID.AhsID.pattern.toString().slice(1, -1)
      },
      [AhsID.ParentAhsID.key]: {
        oneOf: [
          {
            type: AhsID.ParentAhsID.type,
            pattern: AhsID.ParentAhsID.pattern.toString().slice(1, -1)
          },
          { type: 'string', const: '' },
          { type: 'null' }
        ]
      },
      [AhsID.AhsCoID.key]: {
        type: AhsID.AhsCoID.type,
        pattern: AhsID.AhsCoID.pattern.toString().slice(1, -1)
      },
      [NAME.description.key]: {
        oneOf: [
          {
            type: NAME.description.type,
            pattern: NAME.description.pattern.toString().slice(1, -1),
            isNotEmpty: true,
            minLength: NAME.description.minLength,
            maxLength: NAME.description.maxLength
          },
          { type: 'null' },
          { type: 'string', pattern: '^\\s*$' }
        ]
      },
      [NEEDS.InitStationHasHeatNeeds.key]: {
        type: NEEDS.InitStationHasHeatNeeds.type
      },
      [NEEDS.InitStationHasColdNeeds.key]: {
        type: NEEDS.InitStationHasColdNeeds.type
      },
      [NEEDS.InitStationHasHotWaterNeeds.key]: {
        type: NEEDS.InitStationHasHotWaterNeeds.type
      },
      [NEEDS.InitRefreshingEnabled.key]: {
        type: NEEDS.InitRefreshingEnabled.type
      },
      [ENERGY_STORAGE.InitBtesEnabled.key]: {
        type: ENERGY_STORAGE.InitBtesEnabled.type
      },
      [ENERGY_STORAGE.ItesEnabled.key]: {
        type: ENERGY_STORAGE.ItesEnabled.type
      },
      [GEOSTORAGE.InitLoopIsUsed.key]: {
        type: GEOSTORAGE.InitLoopIsUsed.type
      },
      [GEOSTORAGE.InitLoopWaterType.key]: {
        type: GEOSTORAGE.InitLoopWaterType.type,
        enum: GEOSTORAGE.InitLoopWaterType.values
      },
      [GEOSTORAGE.InitHPAInjectionEnabled.key]: {
        type: GEOSTORAGE.InitHPAInjectionEnabled.type
      },
      [HEAT_PRODUCTION.InitTemperatureHeat.key]: {
        type: HEAT_PRODUCTION.InitTemperatureHeat.type,
        minimum: HEAT_PRODUCTION.InitTemperatureHeat.min,
        maximum: HEAT_PRODUCTION.InitTemperatureHeat.max
      },
      [HEAT_PRODUCTION.InitTemperatureHeatBack.key]: {
        type: HEAT_PRODUCTION.InitTemperatureHeatBack.type,
        minimum: HEAT_PRODUCTION.InitTemperatureHeatBack.min,
        maximum: HEAT_PRODUCTION.InitTemperatureHeatBack.max
      },
      [HEAT_PRODUCTION.InitTemperatureHeatDelta.key]: {
        type: HEAT_PRODUCTION.InitTemperatureHeatDelta.type
      },
      [HEAT_PRODUCTION.InitWaterLawIsUsed.key]: {
        type: HEAT_PRODUCTION.InitWaterLawIsUsed.type
      },
      [HEAT_PRODUCTION.InitStationTemperatureSetPoint.key]: {
        type: HEAT_PRODUCTION.InitStationTemperatureSetPoint.type,
        minimum: waterLawIsUsed
          ? HEAT_PRODUCTION.InitStationTemperatureSetPoint.min
          : undefined,
        maximum: waterLawIsUsed
          ? HEAT_PRODUCTION.InitStationTemperatureSetPoint.max
          : undefined
      },
      [HEAT_PRODUCTION.InitStationWaterTankHotTemperatureMin.key]: {
        type: HEAT_PRODUCTION.InitStationWaterTankHotTemperatureMin.type,
        minimum: waterLawIsUsed
          ? HEAT_PRODUCTION.InitStationWaterTankHotTemperatureMin.min
          : undefined,
        maximum: waterLawIsUsed
          ? HEAT_PRODUCTION.InitStationWaterTankHotTemperatureMin.max
          : undefined
      },
      [HEAT_PRODUCTION.InitHPGHeatingEnabled.key]: {
        type: HEAT_PRODUCTION.InitHPGHeatingEnabled.type
      },
      [HEAT_PRODUCTION.InitHPAHeatingEnabled.key]: {
        type: HEAT_PRODUCTION.InitHPAHeatingEnabled.type
      },
      [HEAT_PRODUCTION.InitGasEnabled.key]: {
        type: HEAT_PRODUCTION.InitGasEnabled.type
      },
      [HEAT_PRODUCTION.InitReleveEnabled.key]: {
        type: HEAT_PRODUCTION.InitReleveEnabled.type
      },
      [HEAT_PRODUCTION.InitSolarThermalEnabled.key]: {
        type: HEAT_PRODUCTION.InitSolarThermalEnabled.type
      },
      [COLD_PRODUCTION.InitTemperatureCold.key]: {
        type: COLD_PRODUCTION.InitTemperatureCold.type,
        minimum: COLD_PRODUCTION.InitTemperatureCold.min,
        maximum: COLD_PRODUCTION.InitTemperatureCold.max
      },
      [COLD_PRODUCTION.InitTemperatureColdBack.key]: {
        type: COLD_PRODUCTION.InitTemperatureColdBack.type,
        minimum: COLD_PRODUCTION.InitTemperatureColdBack.min,
        maximum: COLD_PRODUCTION.InitTemperatureColdBack.max
      },
      [COLD_PRODUCTION.InitStationEvapTemperatureDelta.key]: {
        type: COLD_PRODUCTION.InitStationEvapTemperatureDelta.type
      },
      [COLD_PRODUCTION.InitHPGCoolingEnabled.key]: {
        type: COLD_PRODUCTION.InitHPGCoolingEnabled.type
      },
      [COLD_PRODUCTION.InitHPACoolingEnabled.key]: {
        type: COLD_PRODUCTION.InitHPACoolingEnabled.type
      },
      [COLD_PRODUCTION.InitGeocoolingEnabled.key]: {
        type: COLD_PRODUCTION.InitGeocoolingEnabled.type
      },
      [COLD_PRODUCTION.InitColdRangeEnabled.key]: {
        type: COLD_PRODUCTION.InitColdRangeEnabled.type
      },
      [HOTWATER_PRODUCTION.InitTemperatureHotWater.key]: {
        type: HOTWATER_PRODUCTION.InitTemperatureHotWater.type,
        minimum: HOTWATER_PRODUCTION.InitTemperatureHotWater.min,
        maximum: HOTWATER_PRODUCTION.InitTemperatureHotWater.max
      },
      [HOTWATER_PRODUCTION.InitTemperatureHotWaterBack.key]: {
        type: HOTWATER_PRODUCTION.InitTemperatureHotWaterBack.type,
        minimum: HOTWATER_PRODUCTION.InitTemperatureHotWaterBack.min,
        maximum: HOTWATER_PRODUCTION.InitTemperatureHotWaterBack.max
      },
      [ELECTRIC_PRODUCTION.InitPhotovoltaicEnabled.key]: {
        type: ELECTRIC_PRODUCTION.InitPhotovoltaicEnabled.type
      },
      [WEATHER.WeatherFileID.key]: {
        type: WEATHER.WeatherFileID.type,
        isNotEmpty: true,
        pattern: WEATHER.WeatherFileID.pattern.toString().slice(1, -1)
      },
      substations: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            [SST.AhsID.key]: {
              type: SST.AhsID.type,
              pattern: SST.AhsID.pattern.toString().slice(1, -1)
            },
            [SST.InitStationID.key]: {
              type: SST.InitStationID.type,
              pattern: SST.InitStationID.pattern.toString().slice(1, -1)
            },
            [SST.ParentInitStationID.key]: {
              oneOf: [
                {
                  type: SST.ParentInitStationID.type,
                  pattern: SST.ParentInitStationID.pattern
                    .toString()
                    .slice(1, -1)
                },
                { type: 'string', const: '' },
                { type: 'null' }
              ]
            },
            [SST.InitBuildingSurface.key]: {
              type: SST.InitBuildingSurface.type,
              minimum: SST.InitBuildingSurface.min,
              maximum: SST.InitBuildingSurface.max
            },
            [SST.InitStationName.key]: {
              type: SST.InitStationName.type,
              isNotEmpty: true,
              minLength: SST.InitStationName.minLength,
              maxLength: SST.InitStationName.maxLength
            },
            [SST.InitStationUse.key]: {
              type: SST.InitStationUse.type,
              enum: SST.InitStationUse.values
            },
            [SST.InitStationOrder.key]: {
              type: SST.InitStationOrder.type,
              minimum: SST.InitStationOrder.min
            },
            [SST.StdFileID.key]: {
              type: SST.StdFileID.type,
              isNotEmpty: true,
              pattern: SST.StdFileID.pattern.toString().slice(1, -1)
            }
          },
          required: [
            SST.AhsID.key,
            SST.InitStationID.key,
            SST.ParentInitStationID.key,
            SST.InitBuildingSurface.key,
            SST.InitStationName.key,
            SST.InitStationUse.key,
            SST.InitStationOrder.key,
            SST.StdFileID.key
          ],
          additionalProperties: false
        }
      }
    },
    allOf: [
      // InitStationHasHeatNeeds, InitStationHasColdNeeds ou InitStationHasHotWaterNeeds doit être vrai
      {
        anyOf: [
          {
            properties: { [NEEDS.InitStationHasHeatNeeds.key]: { const: true } }
          },
          {
            properties: { [NEEDS.InitStationHasColdNeeds.key]: { const: true } }
          },
          {
            properties: {
              [NEEDS.InitStationHasHotWaterNeeds.key]: { const: true }
            }
          }
        ]
      },
      // les stockages doivent suivre les règles suivantes :
      // - InitBtesEnabled est vrai et ItesEnabled est faux
      // - InitBtesEnabled est faux et ItesEnabled est vrai
      // - InitBtesEnabled est faux et ItesEnabled est faux
      {
        oneOf: [
          {
            properties: {
              [ENERGY_STORAGE.InitBtesEnabled.key]: { const: true },
              [ENERGY_STORAGE.ItesEnabled.key]: { const: false }
            }
          },
          {
            properties: {
              [ENERGY_STORAGE.InitBtesEnabled.key]: { const: false },
              [ENERGY_STORAGE.ItesEnabled.key]: { const: true }
            }
          },
          {
            properties: {
              [ENERGY_STORAGE.InitBtesEnabled.key]: { const: false },
              [ENERGY_STORAGE.ItesEnabled.key]: { const: false }
            }
          }
        ]
      },
      // si InitBtesEnabled est vrai alors
      // - ItesEnabled doit être faux
      // - InitHPGHeatingEnabled et/ou InitHPGCoolingEnabled doit être vrai
      {
        if: {
          properties: { [ENERGY_STORAGE.InitBtesEnabled.key]: { const: true } }
        },
        then: {
          properties: {
            [ENERGY_STORAGE.ItesEnabled.key]: { const: false }
          },
          anyOf: [
            {
              properties: {
                [HEAT_PRODUCTION.InitHPGHeatingEnabled.key]: { const: true }
              }
            },
            {
              properties: {
                [COLD_PRODUCTION.InitHPGCoolingEnabled.key]: { const: true }
              }
            }
          ]
        }
      },
      // si ItesEnabled est vrai alors
      // - InitBtesEnabled doit être faux
      // - InitStationHasColdNeeds doit être vrai
      // - InitStationHasHeatNeeds et InitStationHasHotWaterNeeds doivent être faux
      // - les booléens liés à la production chaud doivent être faux
      {
        if: {
          properties: { [ENERGY_STORAGE.ItesEnabled.key]: { const: true } }
        },
        then: {
          properties: {
            [ENERGY_STORAGE.InitBtesEnabled.key]: { const: false }
          },
          properties: { [NEEDS.InitStationHasColdNeeds.key]: { const: true } },
          allOf: [
            {
              properties: {
                [NEEDS.InitStationHasHeatNeeds.key]: { const: false }
              }
            },
            {
              properties: {
                [NEEDS.InitStationHasHotWaterNeeds.key]: { const: false }
              }
            }
          ],
          anyOf: [
            {
              properties: {
                [HEAT_PRODUCTION.InitWaterLawIsUsed.key]: { const: false }
              }
            },
            {
              properties: {
                [HEAT_PRODUCTION.InitHPGHeatingEnabled.key]: { const: false }
              }
            },
            {
              properties: {
                [HEAT_PRODUCTION.InitHPAHeatingEnabled.key]: { const: false }
              }
            },
            {
              properties: {
                [HEAT_PRODUCTION.InitGasEnabled.key]: { const: false }
              }
            },
            {
              properties: {
                [HEAT_PRODUCTION.InitReleveEnabled.key]: { const: false }
              }
            },
            {
              properties: {
                [HEAT_PRODUCTION.InitSolarThermalEnabled.key]: { const: false }
              }
            }
          ]
        }
      },
      // si besoin chaud alors
      // - ItesEnabled doit être faux
      // - l'une des propriétés InitHPGHeatingEnabled, InitHPAHeatingEnabled, InitGasEnabled ou InitSolarThermalEnabled doit être vraie
      {
        if: {
          anyOf: [
            {
              properties: {
                [NEEDS.InitStationHasHeatNeeds.key]: { const: true }
              }
            },
            {
              properties: {
                [NEEDS.InitStationHasHotWaterNeeds.key]: { const: true }
              }
            }
          ]
        },
        then: {
          allOf: [
            {
              properties: { [ENERGY_STORAGE.ItesEnabled.key]: { const: false } }
            }
          ],
          anyOf: [
            {
              properties: {
                [HEAT_PRODUCTION.InitHPGHeatingEnabled.key]: { const: true }
              }
            },
            {
              properties: {
                [HEAT_PRODUCTION.InitHPAHeatingEnabled.key]: { const: true }
              }
            },
            {
              properties: {
                [HEAT_PRODUCTION.InitGasEnabled.key]: { const: true }
              }
            },
            {
              properties: {
                [HEAT_PRODUCTION.InitSolarThermalEnabled.key]: { const: true }
              }
            }
          ]
        }
      },
      // si besoin froid alors InitHPGCoolingEnabled ou InitHPACoolingEnabled doit être vrai
      {
        if: {
          properties: { [NEEDS.InitStationHasColdNeeds.key]: { const: true } }
        },
        then: {
          anyOf: [
            {
              properties: {
                [COLD_PRODUCTION.InitHPGCoolingEnabled.key]: { const: true }
              }
            },
            {
              properties: {
                [COLD_PRODUCTION.InitHPACoolingEnabled.key]: { const: true }
              }
            }
          ]
        }
      }
    ],
    required: [
      AhsID.AhsID.key,
      AhsID.ParentAhsID.key,
      AhsID.AhsCoID.key,
      NAME.description.key,
      NEEDS.InitStationHasHeatNeeds.key,
      NEEDS.InitStationHasColdNeeds.key,
      NEEDS.InitStationHasHotWaterNeeds.key,
      NEEDS.InitRefreshingEnabled.key,
      ENERGY_STORAGE.InitBtesEnabled.key,
      ENERGY_STORAGE.ItesEnabled.key,
      GEOSTORAGE.InitLoopIsUsed.key,
      GEOSTORAGE.InitLoopWaterType.key,
      GEOSTORAGE.InitHPAInjectionEnabled.key,
      HEAT_PRODUCTION.InitTemperatureHeat.key,
      HEAT_PRODUCTION.InitTemperatureHeatBack.key,
      HEAT_PRODUCTION.InitTemperatureHeatDelta.key,
      HEAT_PRODUCTION.InitWaterLawIsUsed.key,
      HEAT_PRODUCTION.InitStationTemperatureSetPoint.key,
      HEAT_PRODUCTION.InitStationWaterTankHotTemperatureMin.key,
      HEAT_PRODUCTION.InitHPGHeatingEnabled.key,
      HEAT_PRODUCTION.InitHPAHeatingEnabled.key,
      HEAT_PRODUCTION.InitGasEnabled.key,
      HEAT_PRODUCTION.InitReleveEnabled.key,
      HEAT_PRODUCTION.InitSolarThermalEnabled.key,
      COLD_PRODUCTION.InitTemperatureCold.key,
      COLD_PRODUCTION.InitTemperatureColdBack.key,
      COLD_PRODUCTION.InitStationEvapTemperatureDelta.key,
      COLD_PRODUCTION.InitHPGCoolingEnabled.key,
      COLD_PRODUCTION.InitHPACoolingEnabled.key,
      COLD_PRODUCTION.InitGeocoolingEnabled.key,
      COLD_PRODUCTION.InitColdRangeEnabled.key,
      HOTWATER_PRODUCTION.InitTemperatureHotWater.key,
      HOTWATER_PRODUCTION.InitTemperatureHotWaterBack.key,
      ELECTRIC_PRODUCTION.InitPhotovoltaicEnabled.key,
      WEATHER.WeatherFileID.key
    ],
    additionalProperties: false
  };
};

module.exports = { getDescriptionSchema };
